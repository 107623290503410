<!-- 条码校对管理 -->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-sousuo font22" ></i> -->
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('单号')">
                        <el-input type="textarea"  :rows="3" v-model="scope.query.code" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('操作人')">
                        <el-input v-model="scope.query.creatname" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('操作时间')" label-width="80px">
                        <el-date-picker  v-model="query.Date" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="stockDetails"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";

export default {
    name: 'CheckHist',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
    },
    mounted:function(){
        this.buildColumns();
        this.list();
    },
    data(){
        return {
            query:{
                length: 100,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                code:null,
                creatname:null,
                Date:[],
            },
            showQuery: false,
            curr: null,
            columns: [],
            stockDetails: [],
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 40},
                {field: 'id', title: this.msg('ID'), minWidth: 60, sortable: true},
                {field: 'code1', title: this.msg('条码1'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'code2', title: this.msg('条码2'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'stockId', title: this.msg('库存ID'), minWidth: 80, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'creatname', title: this.msg('操作人'), minWidth: 80, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'created', title: this.msg('操作时间'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: '', title: this.msg('结果'), minWidth: 80, sortable: true,
                    slots: {
                            default: ({ row }) => {
                                let type = row.stockId;
                                return [
                                    <el-tag >{type==0?'错误':'正确'}</el-tag>
                                ];
                            },
                        }
                },
            ]
        },
        list(){

            if(this.query.code){
                let cs = this.query.code.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query["codes"] = css.join(",");
            }else{
                this.query["codes"] = '';
            }

            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.stock.checkHistList, param)
                .then((result) => {
                    if(result.status){
                        this.stockDetails = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
